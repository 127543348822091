.aboutme,
.brand-logos-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.aboutme-section {
    align-self: stretch;
    max-width: 100%;
}

.aboutme {
    width: 100%;
    position: relative;
    background-color: var(--color-lightsteelblue-200);
    overflow: hidden;
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
}

.aboutme-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    max-width: 100%;
    font-size: var(--font-size-header);
    text-transform: uppercase;
    font-family: var(--outfit);
}