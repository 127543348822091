.professional-container {
    box-sizing: border-box;
    color: var(--color-black);
    font-family: var(--outfit);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: var(--font-size-subheader)
}

.professional-info-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    font-size: var(--font-size-paragraph);
    padding-bottom: 2%;
}

.professional-header {
    font-size: var(--font-size-subheader);
    text-transform: uppercase;
}

.professional-info {
    margin: 0;
}

@media screen and (max-width: 1366px) {
    .professional-container {
        font-size: var(--font-size-17xl);
    }

    .professional-info-container {
        font-size: var(--font-size-5xl);
    }
}

@media screen and (max-width: 1025px) {
    .professional-container {
        font-size: var(--font-size-17xl);
    }

    .professional-info-container {
        font-size: var(--font-size-5xl);
    }
}