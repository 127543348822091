.personal {
    background-color: var(--color-lightsteelblue-100);
    box-sizing: border-box;
    color: var(--color-black);
    font-family: var(--outfit);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: var(--font-size-subheader)
}

.personal-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 80%;
    font-size: var(--font-size-paragraph);
}

.personal-header {
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    font-size: var(--font-size-subheader);
}

.hobbies-header {
    padding-bottom: 2%;
}

.computer-building,
.computer-building-info {
    padding-bottom: 2%;
    position: relative;

}

.computer-container {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.components-list {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    max-width: 100%;
    padding-bottom: 2%;
}

.list-set {
    margin: 0;
    font-family: inherit;
    font-size: inherit;

}











.hobby-description {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
    flex-shrink: 0;
}

.hobbies1,
.hobby {
    justify-content: flex-start;
}

.hobby {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-12xs-1);
}

.hobbies1 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-6xs) 0 var(--padding-5xs);
    box-sizing: border-box;
}

.current-components {
    height: 55.6px;
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    max-width: 100%;
    z-index: 1;
}