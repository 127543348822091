.education {
    background-color: var(--color-lightsteelblue-200);
    padding: var(--padding-24xl) 0 var(--padding-lgi) 0;
    box-sizing: border-box;
    text-align: center;
    font-size: var(--font-size-45xl);
    color: var(--color-black);
    font-family: var(--outfit);
}

.education-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    max-width: 100%;
}

.education-title {
    margin: 0;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    flex-shrink: 0;
    z-index: 1;
    position: relative;
    max-width: 100%;
    align-self: stretch;
}

.asu-logo-1-icon {
    width: 25%;
    position: relative;
    align-self: center;
    max-width: 100%;
    object-fit: cover;
    flex-shrink: 0;
}

.education-details {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    font-size: var(--font-size-17xl);
}

.asu-details {
    margin: 0;
}

.asu {
    margin: 0;
    height: 180px;
    flex: 1;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
}

.capstone-title {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    z-index: 1;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    font-size: var(--font-size-17xl);
}

.capstone-details {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    max-width: 100%;
    font-size: var(--font-size-5xl);
    padding: 0 5% 0 10%;
}


.capstone-info {
    margin-bottom: 0;
}

.psyche-icon {
    width: 100%;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    flex-shrink: 0;
    max-width: 100%;
    z-index: 1;
}

.image-caption {
    padding: 0 5% 0 35%;
}

.capstone-image {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-base);
}

@media screen and (max-width: 1366px) {
    .education {
        font-size: var(--font-size-17xl);
    }

    .capstone-title {
        font-size: var(--font-size-7xl);
    }
}

@media screen and (max-width: 1025px) {
    .education {
        font-size: var(--font-size-17xl);
    }

    .capstone-title {
        font-size: var(--font-size-7xl);
    }
}