.experience {
    position: relative;
    max-width: 100%;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
    flex-shrink: 0;
    z-index: 1;
    align-self: stretch;
    background-color: var(--color-lightsteelblue-100);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    z-index: 1;
    text-align: left;
    font-size: var(--font-size-45xl);
    color: var(--color-black);
    font-family: var(--outfit);
}

.experience-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    max-width: 100%;
}

.experience-list {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 5% 10%;
    gap: var(--gap-9xl);
    font-size: var(--font-size-13xl);
}

.neudesic-experience {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--gap-xl);
}

.neudesic-details {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 10%;
    max-width: 100%;
}

.neudesic {
    margin: 0;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
}

.job-title {
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: var(--font-size-5xl);
}

.project-title {
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-5xl);
    text-transform: capitalize;
    z-index: 1;
}

.project-details {
    font-family: inherit;
    font-size: var(--font-size-5xl);
    padding-left: 5%;
    text-transform: capitalize;
    width: 90%;
    position: relative;
    flex-shrink: 0;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
}