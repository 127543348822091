.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    display: flex;
    flex: 1;
    height: 100%;
}