@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400&display=swap");

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --outfit: Outfit;

  /* font sizes */
  --font-size-button: 20px;
  /* maxs custom sizes */
  --font-size-header: 64px;
  --font-size-subheader: 42px;
  --font-size-paragraph: 24px;
  /* end of custom sizes */
  --font-size-base: 16px;
  --font-size-45xl: 64px;
  --font-size-19xl: 38px;
  --font-size-32xl: 51px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 29px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-13xl: 32px;
  --font-size-7xl: 26px;
  --font-size-131xl: 150px;
  --font-size-18xl: 37px;
  --font-size-41xl: 60px;

  /* Colors */
  --color-lightsteelblue-100: #cdd8ff;
  --color-lightsteelblue-200: #bac9ff;
  --color-cornflowerblue-100: #91a9ff;
  --color-cornflowerblue-200: #8080d7;
  --color-mediumpurple: #6666bd;
  --color-white: #fff;
  --color-black: #000;

  /* Gaps */
  --gap-xl: 20px;
  --gap-20xl: 39px;
  --gap-89xl: 108px;
  --gap-8xl: 27px;
  --gap-35xl: 54px;
  --gap-24xl-5: 43.5px;
  --gap-3xl: 22px;
  --gap-sm: 14px;
  --gap-30xl: 49px;
  --gap-5xl: 24px;
  --gap-4xl: 23px;
  --gap-231xl: 250px;
  --gap-106xl: 125px;
  --gap-12xl: 31px;
  --gap-43xl: 62px;
  --gap-lgi: 19px;
  --gap-12xs-1: 0.1px;
  --gap-6xl: 25px;
  --gap-48xl: 67px;
  --gap-mid: 17px;
  --gap-14xl: 33px;
  --gap-10xl: 29px;
  --gap-9xl: 28px;
  --gap-51xl: 70px;
  --gap-16xl: 35px;
  --gap-18xl: 37px;
  --gap-lg: 18px;
  --gap-81xl: 100px;
  --gap-31xl: 50px;
  --gap-3xs-7: 9.7px;
  --gap-3xs-8: 9.8px;
  --gap-45xl: 64px;
  --gap-base: 16px;
  --gap-13xl: 32px;

  /* Paddings */
  --padding-10xs: 3px;
  --padding-xl: 20px;
  --padding-6xl: 25px;
  --padding-lgi: 19px;
  --padding-base-6: 16.6px;
  --padding-base-4: 15.4px;
  --padding-2xl: 21px;
  --padding-17xl: 36px;
  --padding-25xl: 44px;
  --padding-12xs: 1px;
  --padding-22xl: 41px;
  --padding-422xl: 441px;
  --padding-201xl: 220px;
  --padding-91xl: 110px;
  --padding-113xl: 132px;
  --padding-37xl: 56px;
  --padding-67xl: 86px;
  --padding-62xl: 81px;
  --padding-15xl: 34px;
  --padding-34xl: 53px;
  --padding-18xl: 37px;
  --padding-41xl: 60px;
  --padding-43xl: 62px;
  --padding-12xl: 31px;
  --padding-11xl: 30px;
  --padding-9xs: 4px;
  --padding-24xl: 43px;
  --padding-70xl: 89px;
  --padding-226xl: 245px;
  --padding-9xl: 28px;
  --padding-103xl: 122px;
  --padding-42xl: 61px;
  --padding-3xl: 22px;
  --padding-1470xl: 1489px;
  --padding-725xl: 744px;
  --padding-353xl: 372px;
  --padding-mid: 17px;
  --padding-13xl: 32px;
  --padding-146xl: 165px;
  --padding-19xl: 38px;
  --padding-221xl: 240px;
  --padding-101xl: 120px;
  --padding-63xl: 82px;
  --padding-8xs: 5px;
  --padding-85xl: 104px;
  --padding-326xl: 345px;
  --padding-205xl: 224px;
  --padding-4xl: 23px;
  --padding-49xl: 68px;
  --padding-646xl: 665px;
  --padding-27xl: 46px;
  --padding-10xl: 29px;
  --padding-147xl: 166px;
  --padding-313xl: 332px;
  --padding-sm: 14px;
  --padding-7xs: 6px;
  --padding-20xl: 39px;
  --padding-369xl: 388px;
  --padding-118xl-7: 137.7px;
  --padding-78xl: 97px;
  --padding-39xl: 58px;
  --padding-175xl: 194px;
  --padding-5xs: 8px;
  --padding-29xl: 48px;
  --padding-6xs: 7px;
  --padding-16xl-2: 35.2px;

  /* Border radiuses */
  --br-xl: 20px;
}