.home1 {
  text-decoration: none;
  height: 45px;
  position: relative;
  text-transform: uppercase;
  color: inherit;
  display: inline-block;
}

.about-me {
  white-space: nowrap;
}

.about-me,
.certifications {
  text-decoration: none;
  height: 45px;
  position: relative;
  text-transform: uppercase;
  color: inherit;
  display: inline-block;
  cursor: pointer;
}

.navbar,
.navbar-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.navbar-links {
  margin: 0;
  width: 1092px;
  justify-content: space-between;
  gap: var(--gap-xl);
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--color-black);
  font-family: var(--outfit);
}

.navbar {
  flex: 1;
  justify-content: center;
  padding: var(--padding-4xl) var(--padding-xl) var(--padding-3xl);
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--color-lightsteelblue-100);
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

@media screen and (max-width: 1025px) {
  .navbar-links {
    font-size: var(--font-size-7xl);
  }
}