.skills {
    background-color: var(--color-lightsteelblue-100);
    max-width: 100%;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-45xl);
    color: var(--color-black);
    font-family: var(--outfit);
    display: flex;
    flex-direction: column;
}

.skills-header {
    margin: 0;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    flex-shrink: 0;
    z-index: 1;
    display: flex;
    align-self: center;
}

.skills-icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.skills-description {
    list-style-position: inside;
    flex-direction: column;
    width: 50%;
    font-size: var(--font-size-17xl);
    text-align: center;
}

.skill-description-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
}

.skill-icons-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
}

.skill-icons-1,
.skill-icons-2,
.skill-icons-3,
.skill-icons-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.java-icon,
.react-icon {
    position: relative;
    width: 50%;
    max-width: 100%;
    max-height: 100%;
}

.node-icon {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    width: 50%;
}

.azure-icon {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
    width: 50%;
}

.git-icon,
.github-icon {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    width: 30%;
}

.java-script- {
    margin: 0;
    text-align: left;
}


@media screen and (max-width: 1366px) {
    .java-script- {
        font-size: var(--font-size-7xl);
    }
}

@media screen and (max-width: 1025px) {
    .java-script- {
        font-size: var(--font-size-7xl);
    }
}