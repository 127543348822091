/* Generalized CSS for all items within the Home Page for flexible display */
.header,
.home,
.name-container,
.title-container {
    display: flex;
    align-items: flex-start;
}

.fristName,
.lastName {
    margin: 0;
}

.name-container,
.title-container {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
}

.home-container {
    display: flex;
    flex-direction: column;
}

.header,
.home {
    box-sizing: border-box;
}

.home {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--color-lightsteelblue-200);
    flex-direction: column;
    justify-content: flex-start;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--font-size-41xl);
    color: var(--color-black);
    font-family: var(--outfit);
}

.header {
    width: 75%;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
}

.name-container {
    width: fit-content;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    flex-shrink: 0;
    width: 20%;
    height: auto;
    text-transform: uppercase;
}

.title-container {
    flex: 1;
    text-transform: capitalize;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
    font-size: var(--font-size-13xl);
}

.headshot-icon {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 10%;
    left: 50%;
    max-width: 30%;
    height: auto;
    width: auto\9;
    object-fit: cover;
    z-index: 1;
}

.header .titles p {
    margin-top: 5%;
    margin-bottom: 5%;
}

.download-resume-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-lightsteelblue-200);
    color: var(--color-black);
    font-size: var(--font-size-13xl);
    font-family: var(--outfit);
    text-transform: uppercase;
    border: 1px solid var(--color-black);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

@media screen and (max-width: 1920px) {
    .name-container {
        width: 32% !important;
    }

    .headshot-icon {
        max-width: 35%;
    }

    .header {
        top: 10%;
    }
}

@media screen and (max-width: 1366px) {
    .name-container {
        right: 10%;
    }

    .firstLastName {
        font-size: var(--font-size-41xl);
    }

    .title-container {
        font-size: var(--font-size-7xl);
    }

    .headshot-icon {
        max-width: 35%;
    }
}

@media screen and (max-width: 1025px) {
    .name-container {
        right: 10%;
    }

    .firstLastName {
        font-size: var(--font-size-41xl);
    }

    .title-container {
        font-size: var(--font-size-7xl);
    }

    .headshot-icon {
        max-width: 45%;
    }
}