.email {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    color: var(--color-black);
    font-family: var(--outfit);
    height: 100%;
    flex: 1;
}

.email-container {
    background-color: var(--color-lightsteelblue-200);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    flex: 1;
}

.email-parent {
    font-size: var(--font-size-paragraph);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.email-header {
    font-size: var(--font-size-header);
}

.email-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

.send-email-submit-wrapper {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3% 0 0;
    display: flex;
    align-items: flex-start;
    max-width: 100%;
}


.send-email-submit {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 100%;
    cursor: pointer;
    border: 0;
    padding: var(--padding-base-6) var(--padding-xl) var(--padding-base-4) var(--padding-2xl);
    background-color: var(--color-cornflowerblue-200);
    align-self: stretch;
    border-radius: var(--br-xl);
    flex-direction: row;
    justify-content: center;
    font-size: var(--font-size-button);
    letter-spacing: 0.15em;
    line-height: 27.5px;
    text-transform: uppercase;
    font-family: var(--outfit);
    color: var(--color-white);
    text-align: center;
}