.footer,
.footer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
}

.footer {
    width: 80%;
}

.footer-wrapper {
    align-self: stretch;
    justify-content: center;
    box-sizing: border-box;
    text-align: left;
    font-size: var(--font-size-45xl);
    color: var(--color-black);
    font-family: var(--outfit);
    background-color: var(--color-lightsteelblue-200);
    padding-bottom: 2%;
}

.name-header {
    width: 20%;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    flex-shrink: 0;
    max-width: 100%;
}

.footer-content {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    width: 80%;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2%;
    box-sizing: border-box;
    text-align: center;
    font-size: var(--font-size-button);
}

.footer-links {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
}

.footer-links-list,
.footer-support-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-20xl);
}

.links,
.github,
.linkedin,
.report-bug,
.support,
.suggestions {
    position: relative;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.github,
.linkedin,
.report-bug,
.suggestions {
    cursor: pointer;
}

.contact-me-submit-wrapper {
    width: 25%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3% 0 0;
    display: flex;
    align-items: flex-start;
    max-width: 100%;
}


.contact-me-submit {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 100%;
    cursor: pointer;
    border: 0;
    padding: var(--padding-base-6) var(--padding-xl) var(--padding-base-4) var(--padding-2xl);
    background-color: var(--color-cornflowerblue-200);
    align-self: stretch;
    border-radius: var(--br-xl);
    flex-direction: row;
    justify-content: center;
    font-size: var(--font-size-button);
    letter-spacing: 0.15em;
    line-height: 27.5px;
    text-transform: uppercase;
    font-family: var(--outfit);
    color: var(--color-white);
    text-align: center;
}

.contact-me-submit:hover {
    background-color: var(--color-mediumpurple);
}


@media screen and (max-width: 1366px) {
    .name-header {
        font-size: var(--font-size-13xl);
    }

    .footer-content {
        font-size: var(--font-size-base);
    }

}

@media screen and (max-width: 1025px) {
    .name-header {
        font-size: var(--font-size-13xl);
    }

    .footer-content {
        font-size: var(--font-size-base);
    }
}