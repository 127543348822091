.screenshot-cluster {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-header);
    color: var(--color-black);
    font-family: var(--button);
    height: 100%;
    flex: 1;
}

.azure-fund-container {
    background-color: var(--color-lightsteelblue-200);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    flex: 1;
}

.azure-fundamentals-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.azure-fundamentals-header {
    margin: 0;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
}

.azure-fundamentals-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-paragraph);
}

.microsoft-certification,
.date-recieved {
    position: relative;
    text-transform: capitalize;
    z-index: 1;
}

.fund-credential-id {
    position: relative;
    font-size: var(--font-size-subheader);
    text-transform: capitalize;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
}

.icon {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}

.azure-associate-container {
    background-color: var(--color-lightsteelblue-100);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    flex: 1;
}

.azure-associate-details {
    flex-direction: column;
    max-width: 100%;
}

.azure-associate-certification,
.azure-associate-info {
    font-size: var(--font-size-paragraph);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}







.azure-developer {
    margin: 0;
}

.azure-developer-associate-header {
    margin: 0;
    position: relative;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    font-family: inherit;
    display: inline-block;
    z-index: 1;
}


.credential-ids {
    position: relative;
    text-transform: capitalize;
    display: inline-block;
    z-index: 1;
}

.associate-id,
.azure-associate-details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.associate-id {
    flex-direction: row;
    font-size: var(--font-size-13xl);
}






/* @media screen and (max-width: 2025px) {
    .azure-fundamentals-parent {
        flex: 1;
        min-width: 100%;
    }

    .microsoft-certified-fundamenta-icon {
        flex: 1;
    }

    .frame-parent {
        flex-wrap: wrap;
    }

    .azure-associate-details {
        flex: 1;
        min-width: 100%;
    }

    .microsoft-certified-associate-parent {
        flex-wrap: wrap;
    }
} */

/* @media screen and (max-width: 1500px) {
    .azure-fundamentals-parent {
        min-width: 100%;
    }

    .screenshot-cluster {
        padding-left: var(--padding-313xl);
        padding-right: var(--padding-313xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 975px) {
    .azure-fundamentals {
        font-size: var(--font-size-32xl);
    }

    .credential-id-62cc5c817a15f0f {
        font-size: var(--font-size-7xl);
    }

    .azure-fundamentals-parent {
        min-width: 100%;
    }

    .azure-developer-associate-container {
        font-size: var(--font-size-32xl);
    }

    .credential-id-89d5c180122188b {
        font-size: var(--font-size-7xl);
    }

    .screenshot-cluster {
        gap: var(--gap-lg);
        padding: var(--padding-25xl) var(--padding-147xl) var(--padding-11xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 450px) {
    .azure-fundamentals {
        font-size: var(--font-size-19xl);
    }

    .microsoft-certification,
    .received-april-8th {
        font-size: var(--font-size-base);
    }

    .credential-id-62cc5c817a15f0f {
        font-size: var(--font-size-lgi);
    }

    .azure-fundamentals-parent {
        min-width: 100%;
    }

    .azure-developer-associate-container {
        font-size: var(--font-size-19xl);
    }

    .received-january-12th-container {
        font-size: var(--font-size-base);
    }

    .credential-id-89d5c180122188b {
        font-size: var(--font-size-lgi);
    }

    .screenshot-cluster {
        padding: var(--padding-10xl) var(--padding-xl) var(--padding-xl);
        box-sizing: border-box;
    }
} */